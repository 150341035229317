<template>
  <div class="instructionsTable">
    <p>合作伙伴SDK或相关技术获取用户信息情况披露</p>
    <table class="table">
      <tr>
        <td><p style="width: 30px">名称</p></td>
        <td>公司</td>
        <td>获取信息</td>
        <td>目的</td>
        <td>隐私条款</td>
      </tr>
      <tr>
        <td>友盟</td>
        <td>友盟同欣(北京)科技有限公司</td>
        <td>设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）</td>
        <td>平台用户使用效果统计分析，并通过地理位置校准报表数据准确性，提供基础反作弊能力</td>
        <td align="left">/</td>
      </tr>
      <tr>
        <td>一键登录</td>
        <td>深圳市和讯华谷信息技术有限公司</td>
        <td>设备Mac地址、IMEI、手机号、设备制造商、网络类型、手机操作系统、屏幕尺寸、运营商类型、ip地址等设置信息</td>
        <td>设备登录认证</td>
        <td align="left">中国移动认证服务<br/>
          <a href="http://wap.cmpassport.com/resources/html/contract.html">http://wap.cmpassport.com/resources/html/contract.html</a><br/><br/>
          中国电信认证服务<br/>
          <a href="https://e.189.cn/sdk/agreement/detail.do?hidetop=true">https://e.189.cn/sdk/agreement/detail.do?hidetop=true</a><br/><br/>
          中国联通认证服务<br/>
          <a href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true">https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true</a>
        </td>
      </tr>
      <tr>
        <td>阿里百川</td>
        <td>浙江淘宝网络有限公司、淘宝（中国）软件有限公司</td>
        <td>设备Mac地址、IMEI、手机号、设备制造商、网络类型、手机操作系统、屏幕尺寸、运营商类型、ip地址等设置信息</td>
        <td>向用户推送契合的商品及/或服务，用户环境安全性检查</td>
        <td align="left">/</td>
      </tr>
      <tr>
        <td>支付宝</td>
        <td>支付宝（中国）网络技术有限公司</td>
        <td>支付宝账号ID、真实姓名、账号名、实名认证</td>
        <td>帮助用户完成三方登录注册</td>
        <td align="left">/</td>
      </tr>
      <tr>
        <td>京东联盟</td>
        <td>北京京东叁佰陆度电子商务有限公司</td>
        <td>设备Mac地址、IMEI、设备制造商、网络类型、手机操作系统、屏幕尺寸、运营商类型、ip地址等设置信息</td>
        <td>向用户推送契合的商品及/或服务，提供基础反作弊能力</td>
        <td align="left">/</td>
      </tr>
      <tr>
        <td>微信登录</td>
        <td>深圳市腾讯计算机系统有限公司</td>
        <td>设备Mac地址、IMEI、设备制造商、网络类型、手机操作系统、屏幕尺寸、运营商类型、ip地址等设置信息</td>
        <td>用户标识，提供基础反作弊能力</td>
        <td></td>
      </tr>
      <tr>
        <td>团油</td>
        <td>车主邦（北京）科技有限公司</td>
        <td>手机号码</td>
        <td>免登陆&用户标记</td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>

export default {
  name: 'instructionsTable',
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.instructionsTable {
  & > p {
    padding: 10px;
  }

  .table {
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;

    td {
      border: 1px solid #e4dcdc;
      padding: 5px 5px;
    }
  }
}
</style>
